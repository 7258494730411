import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  AboutUs,
  AddLinksPage, CalendarPage, CancellationRefundPolicy, ChangeResetPasswordPage, ChangeThemePage, ChatHomePage, ChatPage, ContactUsPage, DeleteOrDeactivatePage, DetailsPage,
  DosDontsPage,
  EditProfilePage,
  EmergencyPage,
  EventBookingPage,
  FAQPage,
  FavouritePlacesProfile, Feedback, ForgotPasswordPage, GuideDetailsPage, GuideHomePage, GuidesListPage, HomePage, LoginPage,
  MembershipPage,
  MembershipsPage,
  MessagesTheme,
  MyProfilePage, MyWalletPage, NotificationPage, PersonalizedTour, PreviewProfilePage, RateAndReview, SearchPage, SettingsPage, SignupPage, SupportPage, TermsAndConditionsPage, UnblockProfilePage, UpdateContact, UpdateUsername, WelcomePage
} from './pages';
import { ContactVerification, IdentityVerification, PersonalInformation, PersonalizeAccount , BankDetailsVerification, AdditionalDocuments, TouristIdentityVerification} from './pages/RegisterationWizardPage';
import { useContext, useEffect, useRef, useState } from 'react';
import { StateContext } from './context/stateContext';
import { Loader } from './components';
import { websocketurl } from './config';
import { useNotify } from './components/Toast';
import { getNotificationMetaInformation, loginInfo, memberships, userActive } from './utils/service';
import constants from './constants';
import PrivacyPolicy from './pages/PrivacyPolicyPage';

export default function Navigation() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { userInfo, setUserInfo, chatListHandler, setShowNewMessageBadge, setShowNotificationBadge, setShowNewBookingBadge, setNotificationIds } = useContext(StateContext);
  const [loading, setLoading] = useState(true); // Set initial loading state to true
  const socketRef = useRef(null);
  const {notify} = useNotify()
  const timerRef = useRef()

  const handleNotificationBadge = (eventType) => {
    const pathname = window.location.pathname
    // for badge handling
    if(eventType?.includes(constants.message) && pathname !== '/Chat' && pathname !== '/ChatHome'){
      setShowNewMessageBadge(true)
    }
    if(eventType?.includes(constants.booking)){
      setShowNewBookingBadge(true)
    }
    if(eventType === constants.notification){
      setShowNotificationBadge(true)
    }
    if(eventType === constants.bookingAndNotificationAndMessage){
      setShowNotificationBadge(true)
      setShowNewBookingBadge(true)
      setShowNewMessageBadge(true)
    }
    if(eventType === constants.notificationAndMessage){
      setShowNotificationBadge(true)
      setShowNewMessageBadge(true)
    }
    if(eventType === constants.bookingAndNotification){
      setShowNotificationBadge(true)
      setShowNewBookingBadge(true)
    }
  }
  
  // Use ref to store the WebSocket connection
  const connectWebSocket = () => {
    if(!socketRef.current && userInfo?.id){
      socketRef.current = new WebSocket(`${websocketurl}/ws/general-notifications/${userInfo?.id}/`);
      
      socketRef.current.onmessage = (event) => {
        const pathname = window.location.pathname
        const data = JSON.parse(event.data)
        if(data?.message?.includes('ignore')){
          setTimeout(()=>{
            chatListHandler()
          }, 1000)
          return
        }
        
        handleNotificationBadge(data?.eventType)

        if(pathname === '/ChatHome'){
            chatListHandler()
        }
        else if(pathname === '/Chat'){
          if(!data.message.includes('new message from') && !data.message.includes('<b>')){
            notify(data.message)
          }
        }
        else{
          if(data?.message?.includes('<b>')){
            return notify(data?.message?.split('<b>')[0] || 'You have new booking 🎉')
          }
          notify(data.message)
        }
      };
      // Handle WebSocket closure
      socketRef.current.onclose = () => {
        // console.log("disconnected.", new Date());
      };
    }
  }
  
  useEffect(()=>{
    if(userInfo){
        connectWebSocket()
        // User is active (online)
        userActive(true).then(res => {}).catch(err => console.log(err));
        const notificationIdsArray = []
        getNotificationMetaInformation().then(res => {
          res?.data?.map(item => {
            return(
              (
                handleNotificationBadge(item?.eventType),
                notificationIdsArray.push({'eventType':item?.eventType, 'id': item.id})
              )
            )
          })
          setNotificationIds(notificationIdsArray)
        }).catch(err => {})
        // When the user leaves the page, set active to false
        const handleBeforeUnload = () => {
          userActive(false).then(res => {}).catch(err => console.log(err));
          socketRef?.current?.close()
          socketRef.current = null
        };
    
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'hidden') {
            // User is not active (offline)
            userActive(false).then(res => {}).catch(err => console.log(err));
            socketRef?.current?.close()
            socketRef.current = null
          } else {
            // User is active again
            userActive(true).then(res => {}).catch(err => console.log(err));
            connectWebSocket()
            loginInfo().then(res => {
              setUserInfo(res.data)
              localStorage.setItem('auth-cg', JSON.stringify(res.data))
            }).catch(err => {
              localStorage.removeItem('auth-cg')
              setUserInfo(false)
            })
            getNotificationMetaInformation().then(res => {
              res?.data?.map(item => (
                handleNotificationBadge(item?.eventType)
              ))
            }).catch(err => {})
          }
        };

        // check if user has active membership 
        // if not then for every 2 mins redirect them to membership page 

        const redirectToMembership = () => {
          memberships().then(res => {
            if(!res.data.is_active){
              if(window.location.pathname !== '/Membership'){
                window.location.href = window.location.origin + '/Membership'
              }
              if(timerRef.current){
                clearTimeout(timerRef.current)
              }
              timerRef.current = setTimeout(()=>{
                redirectToMembership()
              }, 900000)
            }
          }).catch(err => {
            setUserInfo(false)
            localStorage.removeItem('auth-cg')
          })
        }
        
        timerRef.current = setTimeout(()=>{
          redirectToMembership()
        }, 900000)
    
        // Detect when the page is being unloaded (e.g., user closes or refreshes the tab)
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Detect when the tab is hidden or visible (e.g., user switches tabs)
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        // Clean up event listeners when the component is unmounted
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
          document.removeEventListener('visibilitychange', handleVisibilityChange);
          clearTimeout(timerRef.current);
        };
    }
    // eslint-disable-next-line
  }, [userInfo])

  useEffect(() => {
    const userInfo_ = localStorage.getItem('auth-cg') ? JSON.parse(localStorage.getItem('auth-cg')) : null;
    if (userInfo_ || userInfo) {
      setIsAuthenticated(true);
      loginInfo().then(res => {
        setUserInfo(res.data)
        localStorage.setItem('auth-cg', JSON.stringify(res.data))
      }).catch(err => {
        setUserInfo(false)
        localStorage.removeItem('auth-cg')
      }).finally(()=>{
        setLoading(false);
      })
    }
    else{
      setLoading(false)
    }
    return () => {
      if(socketRef.current){
        socketRef?.current?.close()
        socketRef.current = null
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(!loading){
      if (userInfo) {
        setIsAuthenticated(true);
      }
      else{
        setIsAuthenticated(false);
      }
    }
  // eslint-disable-next-line
  }, [userInfo]);

  return (
    <Router>
      {loading ? (
        <Loader />
      ) : (
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route path='/Login' element={<LoginPage />} />
              <Route path='/Signup' element={<SignupPage />} />
              {/* Verification pages */}
              <Route path='/Verification-wizard/contact-verification' element={<ContactVerification />} />
              <Route path='/Verification-wizard/personal-information' element={<PersonalInformation />} />
              <Route path='/Verification-wizard/personalize-account' element={<PersonalizeAccount />} />
              <Route path='/EventBooking' element={<EventBookingPage />} />
              {/* Verification pages */}
              <Route path='*' element={<LoginPage />} />
            </>
          ) : (
            <>
              {userInfo?.isGuide ?
                <Route path='/Home' element={<GuideHomePage />} />
              :
                <Route path='/Home' element={<HomePage />} />
              }
              <Route path='/Search' element={<SearchPage />} />
              <Route path='/Details' element={<DetailsPage />} />
              <Route path='/My-Profile' element={<MyProfilePage />} />
              {/* profile verification page */}
              <Route path='/Profile-Verification/identity-verification' element={<IdentityVerification />} />
              <Route path='/Profile-Verification/tourist-identity-verification' element={<TouristIdentityVerification />} />
              <Route path='/Profile-Verification/bank-details-verification' element={<BankDetailsVerification />} />
              <Route path='/Profile-Verification/additional-documents' element={<AdditionalDocuments />} />

              {/* profile verification page */}
              <Route path='/Membership' element={<MembershipPage />} />
              <Route path='/Favourites' element={<FavouritePlacesProfile />} />
              <Route path='/Settings' element={<SettingsPage />} />
              <Route path='/Guides-List' element={<GuidesListPage />} />
              <Route path='/Guide-Details' element={<GuideDetailsPage />} />
              <Route path='/Personalized-Tour' element={<PersonalizedTour />} />
              <Route path='/ChatHome' element={<ChatHomePage />} />
              <Route path='/Calendar' element={<CalendarPage />} />
              <Route path='/Chat' element={<ChatPage />} />
              <Route path='/Rate-Review' element={<RateAndReview />} />
              <Route path='/NotificationPage' element={<NotificationPage />} />
              <Route path='/Add-Links' element={<AddLinksPage />} />
              <Route path='/Change-Theme' element={<ChangeThemePage />} />
              <Route path='/Messages-Theme' element={<MessagesTheme />} />
              <Route path='/Update-Username' element={<UpdateUsername />} />
              <Route path='/Update-Contact' element={<UpdateContact />} />
              <Route path='/Change-Reset-Password' element={<ChangeResetPasswordPage />} />
              <Route path='/Unblock-Profiles' element={<UnblockProfilePage />} />
              <Route path='/Emergency' element={<EmergencyPage />} />
              <Route path='/Terms-Conditions' element={<TermsAndConditionsPage />} />
              <Route path='/Feedback' element={<Feedback />} />
              <Route path='/ContactUs' element={<ContactUsPage />} />
              <Route path='/Support' element={<SupportPage />} />
              <Route path='/Edit-Profile' element={<EditProfilePage />} />
              <Route path='/Preview-Profile-Page' element={<PreviewProfilePage />} />
              <Route path='/My-Wallet' element={<MyWalletPage />} />
              <Route path='/Delete-or-Deactivate' element={<DeleteOrDeactivatePage />} />
              
              {userInfo?.isGuide ?
                <Route path='*' element={<GuideHomePage />} />
                : 
                <Route path='*' element={<HomePage />} />
              }
            </>
          )}
          {/* <Route path='/' element={<UserTypePage />} /> */}
          <Route path='/' exact element={<WelcomePage />} />
          <Route path='/FAQ' element={<FAQPage />} />
          <Route path='/Privacy-Policy' element={<PrivacyPolicy />} />
          <Route path='/Cancellation-Refund-Policy' element={<CancellationRefundPolicy />} />
          <Route path='/Best-Practices' element={<DosDontsPage />} />
          <Route path='/Forgot-Password' element={<ForgotPasswordPage />} />
          <Route path='/About-Us' element={<AboutUs />} />
          <Route path='/All-Memberships' element={<MembershipsPage />} />
        </Routes>
      )}
    </Router>
  );
}
