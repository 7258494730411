import React from 'react'
import { PiCaretLeftLight  } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

const BackButton = ({size, text='Back', onClick, ...props}) => {
  
  const navigate = useNavigate()

  return (
    <div onClick={onClick ? onClick : window.location.pathname === '/Membership' ? ()=> navigate('/') : ()=>navigate(-1)} className='cursor-pointer d-flex items-center px-10 my-20 width-max-content' {...props}>
      <PiCaretLeftLight className='custom-svg dark-mode-text' size={size || 24}  />
      <span className='dark-mode-text'>{text}</span>
    </div>
  )
}

export default BackButton