import React, { useContext, useEffect, useRef, useState } from 'react'
import {BackButton, ButtonComponent, Loader, ModalContentContainer,} from '../../components'
import {  PiArrowRight, PiDotsThreeOutlineVerticalThin, PiInfo, PiMapPinFill , PiPhoneCallFill, PiShareNetworkFill, PiWarning, PiX} from 'react-icons/pi'
import { animated, useSpring } from '@react-spring/web'
import { blockUser, ChatSocket, createNotification, fetchBlockStatus, GeneralSocket, getRoomName, guides } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import CountryFlag from 'react-country-flag';
import { useLocation, useNavigate } from 'react-router-dom'
import { StateContext } from '../../context/stateContext'
import { TbPhoneCheck } from 'react-icons/tb'
import { LiaHandPointRightSolid } from 'react-icons/lia'
import { AiOutlineStop } from 'react-icons/ai'
import constants from '../../constants'
import { REPORT_MENU } from '../GuideDetailsPage'

const PreviewProfilePage = () => {
  const {userInfo, darkMode} = useContext(StateContext)
  const [mute, setMute] = useState(true)
  const [dotsClickCount, setDotsClickCount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [guideDetail, setGuideDetail] = useState({})
  const [showReportModal, setShowReportModal] = useState(false)
  const [reportType, setReportType] = useState('')
  const [contactRequested, setContactRequested] = useState(false)
  const [socialRequested, setSocialRequested] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)
  const [blockedByMessage, setBlockedByMessage] = useState('')
  const [loading, setLoading] = useState(false)
  // const [isSaved, setIsSaved] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const generalSocketRef = useRef()
  const chatSocketRef = useRef()
  const {userDetailsId, userId} = location.state || {};
  const slideAnimation = useSpring({
    transform: !mute ? 'translateY(0%)' : 'translateY(-100%)',
    config: { tension: 100, friction: 10 }, // Spring-like configuration
  });
  const { notify } = useNotify()

  const slideAnimation1 = useSpring({
    transform: mute ? 'translateY(0%)' : 'translateY(1%)',
    config: { tension: 200, friction: 9 }, // Spring-like configuration
  });

  let timer;
  const handleCloseModal = () => {
    if(timer){
      clearTimeout(timer)
    }
    timer = setTimeout(()=>{
      if(showModal){
        setShowModal(false);setDotsClickCount(0);setMute(!mute);
      }
    }, 50)
  }


  const sendNotificationSocketHandler = (message, type=constants.notification) => {
    GeneralSocket(guideDetail?.userDetails?.id, guideDetail?.userDetails?.username, generalSocketRef, message, type)
  }

  function isGreaterThanSixHours(key) {
    const storedTime = localStorage.getItem(key); // Retrieve the stored time
    if (!storedTime) return true; // If no time is stored, return false

    const storedDate = new Date(JSON.parse(storedTime)); // Convert to a Date object
    const currentDate = new Date(); // Get the current date and time

    // Calculate the difference in hours
    const timeDifference = (currentDate - storedDate) / (1000 * 60 * 60); // Convert ms to hours

    return timeDifference > 6; // Check if difference is greater than 6 hours
}

  const contactRequestHandler = async (isSocial) => {
    if(isBlocked){
      return notify(blockedByMessage.toLowerCase() === 'you have blocked this user.' ? 'You have blocked this user.' : 'User has blocked your account')
    }

    // if more than six hours are not passed
    if (!isGreaterThanSixHours('cgscl') && isSocial) {
      return notify('Existing social request already exist.')
    }
    
    if (!isGreaterThanSixHours('cgcnt') && !isSocial) {
      return notify('Existing contact request already exist.')
    }
    
    const notificationMsg = `${userInfo?.fullName} has requested for your ${isSocial ? 'Social Media Account' : 'contact.'}`
    const message = isSocial ? `Hey, if you don't mind, would you like to share your social media accounts ?` :  `Hey , Can you please share your contact details with me ?`
    sendNotificationSocketHandler(notificationMsg, constants.message)
    const room_name = await getRoomName(userInfo?.id, userInfo?.username, guideDetail?.userDetails?.id, guideDetail?.userDetails?.username)
    ChatSocket(room_name, userInfo?.username, message , chatSocketRef)
    notify('Message request sent to the user.')
    if(isSocial){
      localStorage.setItem('cgscl', JSON.stringify(new Date()))
    }
    if(!isSocial){
      localStorage.setItem('cgcnt', JSON.stringify(new Date()))
    }
    if (isSocial && !socialRequested) {
      return setSocialRequested(!socialRequested)
    }
    if(!contactRequested){
      setContactRequested(!contactRequested)
    }
  }

  const getBlockStatus = () =>{
    // check if the current userid has blocked the authenticated user
    fetchBlockStatus(userId).then(res => {
      setIsBlocked(res.data.status)
      setBlockedByMessage(res.data.blockedBy)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  const notificationHandler = (msg) => {

    if(msg.includes('Reported')){
      if(reportType.length <= 0){
        return notify('please choose a report type')
      }
    }
    
    const message = msg.includes('Reported') ? `Someone ${msg}` : `${userInfo?.fullName} ${msg}`
    createNotification(guideDetail?.userDetails?.username, message).then(res =>{
      notify('notification sent to the user.')
      sendNotificationSocketHandler(`${message}`)
      setShowReportModal(false)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }


  const blockHandler = () => {
    blockUser(guideDetail?.user).then(res => {
      const blockStatus = isBlocked && blockedByMessage.toLowerCase() === 'you have blocked this user.' ? 'Unblocked' : 'Blocked'
      notify(`User ${guideDetail?.userDetails?.fullName} is ${blockStatus}.`)
      getBlockStatus()
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      })
  }

  useEffect(()=>{
    getBlockStatus()
    guides(null, userDetailsId).then(res => {
      setGuideDetail(res?.data)
      // setIsSaved(res?.data?.bookmarked)
      setLoading(false)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
    // eslint-disable-next-line
  },[])

  return (
    <>
      {loading ?
        <Loader />
      :
      <div className={`${darkMode ? 'dark-mode' : ''}`}>
        {showReportModal &&
          <div className='guide-details-report-modal'>
              <div style={{ display:"flex", flexDirection:"column", justifyContent:"center"}}>
                {REPORT_MENU.map((item, index)=>{
                  return(
                    <div key={index} className='cursor-pointer d-flex my-5 items-center'>
                      <input onClick={()=>setReportType(item.title)} name='a' type='radio' />
                      <p className='px-4'>{item.title}</p>
                    </div>
                  )
                })}
                <div className='d-flex items-center'>
                  <ButtonComponent onClick={()=>setShowReportModal(false)} style={{fontSize:'15px', padding:6}} buttonTextColor='black' title='Cancel' wrapperContainerStyle={{margin:'8px 2px', width:"50%", padding:4, borderRadius:4, backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)" }}/>
                  <ButtonComponent onClick={()=>notificationHandler('Reported your profile for ' + reportType)} title='Submit' style={{fontSize:'15px', padding:5}} wrapperContainerStyle={{ margin:'8px 2px', width:"50%", padding:4, borderRadius:4,  }}/>
                </div>
              </div>
          </div>
        }
        <animated.div onClick={()=>handleCloseModal()} className='my-profile-page-container' style={slideAnimation1}>
          {/* My Profile :  text */}
          <div className='my-20 d-flex space-between items-center position-relative'>
            <BackButton size={24} style={{marginLeft:'-10px'}}/>
            <h1 className='title-text dark-mode-text'>My Profile</h1>
            {/* option menu and its content */}
            <animated.div style={slideAnimation} className={`${showModal ? '' : 'hide'} modal-container-mypf border-rouded`}>
              <ModalContentContainer Icon={LiaHandPointRightSolid} text='Nudge' onClick={()=>notificationHandler('nudged you.')}/>
              <ModalContentContainer color Icon={PiWarning} text='Report' onClick={()=>setShowReportModal(true)}/>
              <ModalContentContainer Icon={AiOutlineStop} text={isBlocked && blockedByMessage.toLowerCase() === 'you have blocked this user.' ? 'Unblock' : 'Block'} onClick={()=>blockHandler()}/>
              <ModalContentContainer Icon={PiInfo} text='Help' onClick={()=>navigate('/FAQ')}/>
            </animated.div>
              {guideDetail?.userDetails?.username === userInfo?.username ?
                <PiDotsThreeOutlineVerticalThin className='dark-mode-text' onClick={()=>notify('you cant perform operations on your own account')} size={30}/>
              :
            <>
              {dotsClickCount === 0 &&
                <PiDotsThreeOutlineVerticalThin className='dark-mode-text' onClick={()=>{setMute(!mute);setDotsClickCount(1)}} size={30}/>
              }
              {dotsClickCount === 1 && 
                <PiDotsThreeOutlineVerticalThin className='dark-mode-text' onClick={()=>{setShowModal(true);setDotsClickCount(2)}} size={30}/>
              }
              {dotsClickCount === 2 && 
                <PiX onClick={()=>{setShowModal(false);setDotsClickCount(0);setMute(!mute);}} className={`cursor-pointer vertical-dots custom-svg svg-block rounded-svg ${showModal ? 'bg-white' : ''}`}/>
              }
            </>
          }
          </div>

          {/* Basic details container */}
          <div className='d-flex items-center'>
            
            {/* Image */}
            <img className='profile-image' src={guideDetail?.profileImage} alt='user' />
            
            {/* Profile details container */}
            <div className='d-flex column px-20'>
              <div className='d-flex items-center'>
                <h4 className='name dark-mode-text' style={{margin:'0px 4px'}}>{guideDetail?.userDetails?.fullName}</h4>
                {guideDetail?.showCountryFlag &&
                  <CountryFlag className='country-flag' countryCode={guideDetail?.countryCode} svg style={{ marginLeft:3, marginBottom:2, width: '18px', height: '18px', borderRadius:"0% !important" }} />
                }
              </div>
                
              
              <div className='d-flex my-2 items-center'>
                <p className='text-lightgray fs-14'>&nbsp;@</p>
                <p className='city fs-10'>&nbsp;{guideDetail?.userDetails?.username}</p>
              </div>
              <div className='d-flex my-2 items-center'>
                <PiMapPinFill  size={16} color='var(--primary)' />
                <p className='city'>&nbsp;{guideDetail?.city}</p>
              </div>
              <div className='d-flex my-2 items-center'>
                <TbPhoneCheck fill='var(--primary)' strokeWidth={1} color='var(--primary)' />
                <p className='city'>&nbsp;Phone Verified</p>
              </div>
            </div>
          </div>

          {/* Info : text */}
          <div style={{overflow:"hidden"}}>
            <animated.h3 className={`subtitle-text dark-mode-text ${!mute ? '' : 'mute'}`} style={slideAnimation}>Info</animated.h3>

            <animated.div className={`info-card-container space-evenly ${!mute ? '' : 'mute'}`} style={slideAnimation}>
              {/* Left container */}
              <div className='left-container'>
                {guideDetail?.isGuide ?
                <>
                  <p className='info-title'>Service Location</p>
                  <p className='info-subTitle'>{guideDetail?.cityOfService}</p>
                </>
                :
                <>
                <p className='info-title'>City & State</p>
                <p className='info-subTitle'>{guideDetail?.city},{guideDetail?.state}</p>
                </>
                }

                <p className='info-title'>Age</p>
                <p className='info-subTitle'>{new Date().getFullYear() - new Date(guideDetail?.dob).getFullYear() }</p>

                <p className='info-title'>Interest & Hobbies</p>
                <p className='info-subTitle'>{guideDetail?.hobbiesAndInterest?.split(",")?.join(', ', '')}</p>
              </div>
              {/* Right container */}
              <div className='right-container'>
                <p className='info-title'>Languages</p>
                <p className='info-subTitle'>{guideDetail?.languages?.map(item => (item.language+', '))}</p>

                <p className='info-title'>Gender</p>
                <p className='info-subTitle'>{guideDetail?.gender}</p>

                <p className='info-title'>Profession</p>
                <div className='d-flex items-center'>
                  <p className='info-subTitle'>{guideDetail?.profession?.length > 0 ? guideDetail?.profession : guideDetail?.isStudent ? 'Student' : ''}</p>
                    {guideDetail?.justifyInFewWords?.length > 0 &&
                    <PiArrowRight className='px-3 info-subTitle'/>
                  }
                  <p className='info-subTitle'>{guideDetail?.justifyInFewWords}</p>
                </div>
              </div>
            </animated.div>
          </div>



          {/* About : text */}
          <h3 className='subtitle-text dark-mode-text'>About</h3>

          {/* Description : text */}
          <p className='about-desc-text dark-mode-text'>{guideDetail?.about}</p>

          {/* Contact & Social buttons */}
          <animated.div className={`d-flex my-20 ${!mute ? '' : 'mute'}`} style={slideAnimation}>
            <ButtonComponent  onClick={()=>contactRequestHandler()} Icon={PiPhoneCallFill} wrapperContainerStyle={{width:'50%', margin:'10px 4px 10px 0px'}} title={contactRequested ? 'Requested' : 'Contact'} />
            <ButtonComponent onClick={()=>contactRequestHandler(true)} Icon={PiShareNetworkFill} wrapperContainerStyle={{width:'50%', margin:'10px 0px 10px 4px'}} title={socialRequested ? 'Requested' : 'Social'} />
          </animated.div>

          {/* Gallary : text */}
          <h3 className='subtitle-text dark-mode-text'>Album</h3>

          {/* Image container */}
          <div className='image-container-mp'>
            {guideDetail?.image1 &&
              <img src={guideDetail?.image1} alt='img 1' />
            }
            {guideDetail?.image2 &&
              <img src={guideDetail?.image2} alt='img 1' />
            }
            {guideDetail?.image3 &&
              <img src={guideDetail?.image3} alt='img 1' />
            }
            {guideDetail?.image4 &&
              <img src={guideDetail?.image4} alt='img 1' />
            }
          </div>
        </animated.div>
      </div>
      }
    </>
  )
}

export default PreviewProfilePage