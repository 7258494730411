import React, { useContext, useRef } from 'react'
import { createOrder, GeneralSocket, storeAdvancedOrderDetails, storeMembershipOrderDetails } from '../../utils/service'
import { useNotify } from '../Toast'
import { StateContext } from '../../context/stateContext';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import { razorpayLogo } from '../../assets/images';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const RazorpayComponent = ({id, setSelected, guideId, basicFormId, price, buttonText, hideModal, isMembership, membershipId, promoUsed, promoText}) => {
    const {notify} = useNotify()
    const {userInfo} = useContext(StateContext)
    const generalSocketRef = useRef()
    const navigate = useNavigate()
    async function loadRazorpayModal(amount, orderId, isAdvancedPayment) {
    
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
    
        if (!res) {
          alert(
            "Razorpay failed to load. make sure you have active internet connection"
          );
          return;
        }
    
        const options = {
          key_id: process.env.REACT_APP_RAZORPAY_KEY,
          order_id: orderId,
          name: "City2Guide",
          description: "Thank you for Shopping With us.",
          image: razorpayLogo,
          handler: function (response) {
            const sendData = async () => {
              if(isAdvancedPayment){
                storeAdvancedOrderDetails(guideId, basicFormId, amount, response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature).then(res => {
                  notify('Payment Successful, redirecting...')
                  GeneralSocket(guideId, userInfo?.username, generalSocketRef, `you have received an advanced payment.`, constants.notification)
                }).catch(err =>{
                  notify('error while storing advanced payment details')
                }).finally(()=>{
                  if(hideModal){
                    setTimeout(()=>{
                      hideModal()  // this is nothing but basic booking form modal which is open and once the payment is done we can hide it.
                      navigate('/Home')
                    }, 1000)
                  }
                })
              }
              else if(isMembership){
                storeMembershipOrderDetails(membershipId, promoUsed, promoText, response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature).then(res => {
                  notify('Payment Successful, redirecting...')
                }).catch(err =>{
                  notify('error while storing advanced payment details')
                }).finally(()=>{
                  setTimeout(()=>{
                      if(hideModal){
                        hideModal()  // this is nothing but basic booking form modal which is open and once the payment is done we can hide it.
                      }
                      navigate('/Home')
                  }, 1000)
                })
              }
              else{
                navigate('/Home')
                if(setSelected){
                  setSelected(undefined)
                }
              }
              
            };
            sendData();
          },
          prefill: {
            name: userInfo.fullName,
            email: userInfo.email,
            contact: userInfo.contact,
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }
    const handleCreateOrder = ()=>{
        if(isMembership){
          createOrder(membershipId, guideId, price, promoText).then(res => {
              loadRazorpayModal(res.data.otherData.amount, res.data.order_id, res.data.advancedPayment, res.data.membership)
          }).catch(err => {
              notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
          })
          return
        }
        if(id !== null || id !== undefined){
            createOrder(id, guideId, price, promoText).then(res => {
                loadRazorpayModal(res.data.otherData.amount, res.data.order_id, res.data.advancedPayment, res.data.membership)
            }).catch(err => {
                notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
            })
        }
    }
  
  return (
    <div>
        <button id='razorpay-btn' className='p-10 bg-primary text-whitesmoke border-rounded border shadow cursor-pointer' onClick={()=>handleCreateOrder()}>{buttonText ? buttonText : "Purchase"}</button>
    </div>
  )
}

export default RazorpayComponent