import React from 'react'
import './card.styles.scss'
import RazorpayComponent from '../RazorpayComponent'

const Card = ({index, hidePurchaseButton, activeMembership, daysLeft, _id, promoText, promoUsed, title, desc, price, selected, setSelected}) => {
  // Replace newlines with <br />  
  const formattedDesc = desc.split('\n').map((item, index) => (  
    <span key={index}>  
      {item}  
      <br />  
    </span>  
  ));
  return (
    <div onClick={()=>{setSelected(index)}} className={`card-container ${selected ? 'selected' : ''}`}>
      <div className='d-flex space-between items-center'>
        
        <h1 className='dark-mode-text fs-18 my-10'>{title}{activeMembership && <span className='border border-rounded p-2 fs-14 fw-500 active-tab '>Active</span>}</h1>
        {!activeMembership &&
          <p className='dark-mode-text'>₹{price}</p>
        }
        {activeMembership &&
          <p className='fs-14 dark-mode-gray-text w-30'>({daysLeft} days left. )</p>
        }
      </div>
      <ul className='dark-mode-text'>{formattedDesc}</ul>
      <div className='d-flex flex-end'>
        <div className='circle'/>
      </div>
      {selected && !hidePurchaseButton && <RazorpayComponent setSelected={setSelected} id={index+1} membershipId={_id} isMembership={true} promoUsed={promoUsed} promoText={promoText} />}
    </div>
  )
}

export default Card