import React, { useContext, useState } from 'react'
import IconInput from '../../components/IconInput/IconInput'
import { LuClock4, LuPenLine } from "react-icons/lu";
import { StateContext } from '../../context/stateContext';
import { BackButton, ButtonComponent } from '../../components';
import { BsSkipEndBtnFill } from 'react-icons/bs';
import { TbCategory, TbClockQuestion, TbPencilDown, TbPencilPin, TbPencilUp, TbPhone, TbRating18Plus } from 'react-icons/tb';
import { CgWebsite } from 'react-icons/cg';
import { MdWebStories } from 'react-icons/md';
import { storeEventQuery } from '../../utils/service';
import { useNotify } from '../../components/Toast';
const EventBookingPage = () => {
  const {darkMode} = useContext(StateContext)
  const [arrangeCall, setArrangeCall] = useState(true)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    eventName:'',
    title:'',
    cityName:'',
    fromDate:'',
    toDate:'',
    time:'',
    duration:'',
    description:'',
    website:'',
    typeOfEvent:'',
    eventCategory:'',
    ageLimit:'',
    alternativeContact:'',
    email:''
  })

  const {notify} = useNotify()

  const submitHandler = () => {
    setLoading(true)
    for(let key of Object.keys(data)){
      if(data[key].length === 0){
        setLoading(false)
        console.log(key)
        return notify('Please fill in all the fields')
      }
    }
    storeEventQuery({...data, arrangeCall}).then(res => {
      return notify('Response sent successfully')
    }).catch(err => {
      return notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    }).finally(()=>{
      setLoading(false)
    })
  }
  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <BackButton />
      <div className='px-20'>
        <h2 className='dark-mode-text fw-500 text-center my-20'>Let's plan your event today !</h2>

        <IconInput value={data.eventName} onChange={(e)=>setData({...data, 'eventName':e.target.value})}  Icon={BsSkipEndBtnFill} placeholder='Event Name'/>
        <IconInput value={data.title} onChange={(e)=>setData({...data, 'title':e.target.value})}  Icon={LuPenLine} placeholder='Title'/>
        <IconInput value={data.cityName} onChange={(e)=>setData({...data, 'cityName':e.target.value})}  Icon={TbPencilPin} placeholder='City Name'/>
        <IconInput value={data.fromDate} onChange={(e)=>setData({...data, 'fromDate':e.target.value})}  Icon={TbPencilDown} hideLabel={true} type='date' placeholder='From Date'/>
        <IconInput value={data.toDate} onChange={(e)=>setData({...data, 'toDate':e.target.value})}  Icon={TbPencilUp} hideLabel={true} type='date' placeholder='To Date'/>
        <IconInput value={data.time} onChange={(e)=>setData({...data, 'time':e.target.value})}  Icon={LuClock4} type='time' placeholder='Time'/>
        <IconInput value={data.duration} onChange={(e)=>setData({...data, 'duration':e.target.value})}  Icon={TbClockQuestion} placeholder='Duration (e.g X hours)'/>
        <textarea  value={data.description} onChange={(e)=>setData({...data, 'description':e.target.value})} placeholder='Description'  rows={5} style={{resize:'none', fontSize:16, outline:"none", padding:10, borderRadius:8, width:"95%"}}></textarea>
        <IconInput value={data.website} onChange={(e)=>setData({...data, 'website':e.target.value})}  Icon={CgWebsite} placeholder='Website'/>
        <IconInput value={data.typeOfEvent} onChange={(e)=>setData({...data, 'typeOfEvent':e.target.value})}  Icon={MdWebStories} placeholder='Type Of Event'/>
        <IconInput value={data.eventCategory} onChange={(e)=>setData({...data, 'eventCategory':e.target.value})}  Icon={TbCategory} placeholder='Event Category'/>
        <IconInput value={data.ageLimit} onChange={(e)=>setData({...data, 'ageLimit':e.target.value})}  Icon={TbRating18Plus} placeholder='Age Limit'/>
        <IconInput type='number' value={data.alternativeContact} onChange={(e)=>setData({...data, 'alternativeContact':e.target.value})}  Icon={TbPhone} placeholder='Alternative Contact'/>
        <IconInput type='email' value={data.email} onChange={(e)=>setData({...data, 'email':e.target.value})}  Icon={TbPhone} placeholder='Email'/>
        <div className='d-flex items-center flex-end my-10 px-4'>
          <input onChange={()=>setArrangeCall(prev => !prev)} checked={arrangeCall} type='checkbox' style={{marginRight:10}}/>
          <p className='dark-mode-text'>Arrange a call</p>
        </div>
        <ButtonComponent title={loading ? "Submitting.." : 'Submit'} onClick={submitHandler}/>
      </div>
    </div>
  )
}

export default EventBookingPage