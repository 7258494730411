import React, { useContext, useState } from 'react'
import { StateContext } from '../../context/stateContext'
import { ButtonComponent, IconInput, IconInputDropDown } from '../../components'
import { FaPaypal, FaRupeeSign, FaUser } from 'react-icons/fa'
import { PiBank, PiBuilding, PiCardsThree, PiInfoBold, PiPen, PiUser } from 'react-icons/pi'

const WithdrawlPage = ({hideModal, }) => {
  const {darkMode} = useContext(StateContext)
  const [identityVerificationForm, setIdentityVerificationForm] = useState({
    amount:'',
    accountHolderName:'',
    bankName:'',
    accountNumber:'',
    ifscCode:'',
    branchName:'',
    upi:'',
    accountType:'Savings'
  })
  const changeHandler = (key, text) => {
    if (text && typeof text === 'object' && text instanceof Blob) {
        setIdentityVerificationForm({...identityVerificationForm, [key]:text})
    }
    setIdentityVerificationForm({...identityVerificationForm, [key]:text})
  }
  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
      <div className='my-20 px-10'>
        <div className='d-flex space-between items-center py-5'>
          <h3 className='fw-500 dark-mode-text'>Withdrawal Amount</h3>
          <div className='d-flex items-center'>
            <input style={{height:20, width:20, marginRight:10}} type='checkbox'/>
            <h3 className='fw-500 px-5 dark-mode-text'>All</h3>
          </div>
        </div>
      </div>
      <div className='px-10'>
        <IconInput value={identityVerificationForm?.amount} onChange={(e)=>changeHandler('amount', e.target.value)} placeholder='Enter Rupees' type='number' Icon={FaRupeeSign} />
        <div className='bg-lightyellow border-rounded p-20 d-flex space-between items-center'>
            <PiInfoBold size={40} color='var(--primary)' className='me-2'/>
            <p className='fs-14'>
              It will take 7-10 working days to transfer any amount into your bank account.
            </p>
        </div>
      </div>
      <p className='dark-mode-text fs-18 text-center my-20'>Re-enter bank account details to cofirm.</p>
      <div className='px-10'>
          <IconInput value={identityVerificationForm.accountHolderName} onChange={(e)=>changeHandler('accountHolderName', e.target.value)} Icon={PiUser} placeholder="Account holder's Name" />
          <IconInput value={identityVerificationForm.bankName} onChange={(e)=>changeHandler('bankName', e.target.value)} Icon={PiBank} placeholder="Bank Name" />
          <IconInput value={identityVerificationForm.accountNumber} onChange={(e)=>changeHandler('accountNumber', e.target.value)} type='number' Icon={PiCardsThree} placeholder="Account Number" />
          <IconInput value={identityVerificationForm.ifscCode} onChange={(e)=>changeHandler('ifscCode', e.target.value)} Icon={PiPen} placeholder="IFSC Code" />
          <IconInput value={identityVerificationForm.branchName} onChange={(e)=>changeHandler('branchName', e.target.value)} Icon={PiBuilding} placeholder="Branch Name" />
          <IconInput value={identityVerificationForm.upi} onChange={(e)=>changeHandler('upi', e.target.value)} Icon={FaPaypal} placeholder="UPI Id" />
          <IconInputDropDown Icon={FaUser} value={identityVerificationForm?.accountType} onChange={(e)=>changeHandler('accountType', e.target.value)}  selectData={[
              {id:1, value:'Current', text:"Current"},
              {id:2, value:'Savings', text:"Savings"}
              ]} 
          />
      </div>
      <p className='dark-mode-text text-center px-10 my-5'>Write a mail to us if there are any changes in above details.</p>
      <div className='d-flex space-evenly mt-10'>
        <ButtonComponent onClick={hideModal} title='Cancel' wrapperContainerStyle={{width:"40%"}}/>
        <ButtonComponent title='Request'wrapperContainerStyle={{width:"40%"}}/>
      </div>
    </div>
  )
}

export default WithdrawlPage