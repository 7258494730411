import React, { useContext, useEffect, useRef, useState } from 'react'
import './chatpage.styles.scss'
import { PiCaretRightBold, PiDotsThreeVerticalBold, PiImage, PiInfo, PiPaperPlaneTilt, PiWarningLight  } from 'react-icons/pi'
import  {MdBlock} from 'react-icons/md'
import { guideline } from '../../assets/images'
import { BackButton, BasicBookingForm, BottomDrawer, ButtonComponent, LineText, Loader, ProfileMessage } from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { authAxios, blockUser, chatMetaInformation, convertDateTime, createNotification, fetchBlockStatus, getUserActive, uploadImage } from '../../utils/service'
import { useNotify } from '../../components/Toast'
import { url, websocketurl } from '../../config'
import { StateContext } from '../../context/stateContext'
import constants from '../../constants'
import { REPORT_MENU } from '../GuideDetailsPage'

const InfoContent = ({onClick}) => {
  return(
    <div className='info-content'>
      <p className='dark-mode-text' onClick={onClick}>&times;</p>
      <div className='d-flex column'>
        <img src={guideline} alt='guideline'/>
        <ul>
          <li className='dark-mode-text'>Kindly treat an individual polietly & respectfully.</li>
          <li className='dark-mode-text'>Beware of clicking unwanted websites.</li>
          <li className='dark-mode-text'>Avoid giving any financial help to user.</li>
          <li className='dark-mode-text'>Report a profile incase of suspicious - if you think this account violates our community guidlines<br/>Your report is anonymous</li>
        </ul>
      </div>
    </div>
  )
}

const ChatPage = () => {
  const currentHeight = window.innerHeight
  const { darkMode, userInfo } = useContext(StateContext)
  const [showReportModal, setShowReportModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)
  const [blockedByMessage, setBlockedByMessage] = useState('')
  const [newMessage, setNewMessage] = useState('');
  const [roomName, setRoomName] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false)
  const [showInfoContent, setShowInfoContent] = useState(false)
  const [loading, setLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [reportType, setReportType] = useState('')
  const chatSocketRef = useRef(null);  // Use ref to store the WebSocket connection
  const generalSocketRef = useRef(null);  // Use ref to store the WebSocket connection
  const fetchRoomNameApiRef = useRef(null)
  const chatContainerRef = useRef(null)
  const roomNameRef = useRef(null);
  const inputRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { theme, userId, isGuide, userDetailsId, fullName, username, image, nickName } = location.state || {} 
  const [isActive, setIsActive] = useState(false)
  const [chatBackground] = useState(theme)
  const {notify} = useNotify()

  // Fetch the chat room name based on the user2_id
  const fetchRoomName = async () => {
    const response = await authAxios.post(`${url}/auth/api/create_or_get_chat_room/`, { userId });
    const room_name = response.data.room_name;
    roomNameRef.current = room_name
    setRoomName(room_name);

    // Initialize WebSocket connection with the room name only if it hasn't been initialized
    if (!chatSocketRef.current) {
        chatSocketRef.current = new WebSocket(`${websocketurl}/ws/chat/${room_name}/`);
        generalSocketRef.current = new WebSocket(`${websocketurl}/ws/general-notifications/${userId}/`)
        // Handle incoming WebSocket messages
        chatSocketRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setMessages((prevMessages) => [...prevMessages, data]);
        };

        // after initializing WebSocket connection we first send this event to update the senders tick to orange 
        generalSocketRef.current.onopen = () => {
        generalSocketRef.current.send(JSON.stringify({
            "message":'ignore message',
            "eventType": constants.message,
            "forUser":userId
          }));
        };
        
        // Handle WebSocket closure
        chatSocketRef.current.onclose = () => {
            console.log("disconnected.");
        };
        generalSocketRef.current.onclose = () => {
            console.log("disconnected.");
        };
    }
  };

  // block the user or unblock the user
  const blockHandler = () => {
    blockUser(userId).then(res => {
      const blockStatus = isBlocked && blockedByMessage.toLowerCase() === 'you have blocked this user.' ? 'Unblocked' : 'Blocked'
      notify(`User ${fullName} is ${blockStatus}.`)
      getBlockStatus()
      }).catch(err => {
        notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
      })
  }

  // Fetch all previous messages
  const fetchMessages = async () => {
      if (roomName) {
          setLoading(true)
          authAxios.get(`${url}/auth/api/messages/${roomName}/`).then(response => {
            setMessages(response.data.data);
          }).catch(err =>{ 
            notify('error while fetching the messages, please try again later.')
          }).finally(()=>{
            setLoading(false)
          })
      }
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => file);
    if(selectedImages.length < 6 && newImages.length < 6 && (newImages.length + selectedImages.length) < 6){
      setSelectedImages((prevImages) => [...prevImages, ...newImages]);
    }
    else{
      notify("Max 5 images are allowed.")
    }
  };

  // Remove an image
  const removeImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Send new message via WebSocket
  const sendMessage = async () => {
    // check if the current authenticated user is blocked by another user


    // Step 1: Check if there are images to upload
    if (selectedImages.length > 0) {
      const newImages = selectedImages.map((file) => URL.createObjectURL(file));
      
      try {
        // Wait for the image upload to complete
        const lastMessage = messages[messages.length - 1]
        const lastMessageId = lastMessage?.messageId ? lastMessage.messageId : lastMessage.id
        const response = await uploadImage(selectedImages, lastMessageId, roomName, username);

        if (generalSocketRef.current) {
          generalSocketRef.current.send(JSON.stringify({
            "message": 'new message from ' + userInfo?.username,
            "eventType": constants.message,
            "forUser":userId
          }));
        }

        chatSocketRef.current.send(JSON.stringify({
          'message': response.data.urls,
          'sender': userInfo?.username,
          'chatRoom': roomName,
          'images': selectedImages, // Send images with the message
        }));
        
        // After the upload, handle the newly created URLs
        const data = newImages.map(item => ({
          "id": Math.random(),
          "messageId": lastMessageId,
          "file": item,
          "username": userInfo?.username,
          "createdAt": `2024-09-24T13:04:45.188324Z`
        }));

        // Update the state with the uploaded image data
        let index = messages.length - 1;
        let prevMessage = [...messages];

        if (!prevMessage[index]?.media) {
          prevMessage[index].media = [];
        }

        prevMessage[index]['media'] = [...prevMessage?.[index]?.['media'], ...data];
        setMessages(prevMessage);
        setSelectedImages([]);
  
      } catch (error) {
        console.error("Image upload failed", error);
        notify("Image upload failed.");
        return;  // Stop execution if the upload fails
      }
    }
  
    // Step 2: After the image upload, proceed with sending the message
    if (chatSocketRef.current && newMessage.trim()) {
      chatSocketRef.current.send(JSON.stringify({
        'message': newMessage,
        'sender': userInfo?.username,
        'chatRoom': roomName,
        'images': selectedImages, // Send images with the message
      }));
  
      if (generalSocketRef.current) {
        generalSocketRef.current.send(JSON.stringify({
          "message": 'new message from ' + userInfo?.username,
          "eventType": constants.message,
          'forUser':userId
        }));
      }
  
      // Clear input and image selection after sending
      setNewMessage('');
      setSelectedImages([]);
      scrollToBottom();
    }
  };
  

  const notificationHandler = (msg) => {
    const message = msg.includes('Reported') ? `Someone ${msg}` : `${userInfo?.fullName} ${msg}`
    createNotification(username, message).then(res =>{
      notify('notification sent to the user.', 'black', 'top-center')
      setShowModal(false)
      setShowReportModal(false)
      generalSocketRef.current.send(JSON.stringify({
        "message":message,
        "eventType": constants.notification,
        "forUser":userId
      }));
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message, 'black', 'top-center')
    })
  }

  const getBlockStatus = () =>{
    // check if the current userid has blocked the authenticated user
    fetchBlockStatus(userId).then(res => {
      setIsBlocked(res.data.status)
      setBlockedByMessage(res.data.blockedBy)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  }

  
  useEffect(() => {
    if (userId && !fetchRoomNameApiRef.current) {
        // get block status
        getBlockStatus()
        // Fetch the room name and messages when the component loads
        fetchRoomName();
        fetchRoomNameApiRef.current = true
        getUserActive(userId).then(res=>{
          setIsActive(res.data.status)
        }).catch(err => {
          notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
        })
    }
    return () => {
      if(chatSocketRef.current){
        chatSocketRef?.current?.close()
        generalSocketRef?.current?.close()
      }
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (roomName) {
        fetchMessages();
        chatMetaInformation(roomName).catch(err => console.log(err))
    }
    return () => {
      chatMetaInformation(roomNameRef.current).catch(err => console.log(err))
    }
    // eslint-disable-next-line
  }, [roomName]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }
  
  useEffect(() => {
    scrollToBottom()
  }, [messages]); 

  useEffect(()=>{
    return()=>{
      setShowModal(false)
    }
  },[])

  if(loading){
    return <Loader style={{background:"black", paddingTop: '50%', alignSelf: 'center', minHeight: '80vh', width: '100%'}}/>
  }

  return (
    <div className={`${darkMode ? 'hc-dark-mode': ''}`} style={{height:currentHeight}}>
       {showReportModal &&
          <div className='guide-details-report-modal' style={{marginTop:"40%"}}>
            <div style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center"}}>
              {REPORT_MENU.map((item, index)=>{
                return(
                  <div key={index} className='cursor-pointer d-flex my-5 items-center'>
                    <input onClick={()=>setReportType(item.title)} name='a' type='radio' />
                    <p className='px-4'>{item.title}</p>
                  </div>
                )
              })}
              <div className='d-flex items-center'>
                <ButtonComponent onClick={()=>{setShowReportModal(false); setShowModal(false)}} style={{fontSize:'15px', padding:6}} buttonTextColor='black' title='Cancel' wrapperContainerStyle={{margin:'8px 2px', width:"50%", padding:4, borderRadius:4, backgroundImage:"linear-gradient(to right, whitesmoke, whitesmoke)" }}/>
                <ButtonComponent onClick={()=>notificationHandler('Reported your profile for ' + reportType)} title='Submit' style={{fontSize:'15px', padding:5}} wrapperContainerStyle={{ margin:'8px 2px', width:"50%", padding:4, borderRadius:4,  }}/>
              </div>
            </div>
          </div>
      }
      <div className='chat-scroll-cls'  ref={chatContainerRef} id='scroll' style={{overflowY:'scroll', backgroundImage:`url(${chatBackground})`, height:currentHeight}}>
        {/* Header Container */}
        <div className={`py-10 d-flex space-between items-center header-container-cp ${darkMode ? 'hc-dark-mode' : ""}`}>
            {/* back , image and badge */}
            <div className='d-flex items-center w-100'>
              {/* Back chevron */}
              <div className='px-5'>
                <BackButton size={24} text='' style={{margin:-10}}/>
              </div>
                

              <div onClick={ isGuide ? 
                  ()=>navigate('/Guide-Details', {state:{id:userDetailsId, userId, isProfileView:false}}) 
                  : 
                  ()=>navigate('/Preview-Profile-Page', {state:{userDetailsId:userDetailsId, userId, isProfileView:true}})
                } className='d-flex items-center image-container-cp w-100'>
                <div  className='position-relative'>
                  {/* Profile */}
                  <img style={{background:"white"}} src={image} alt={nickName || fullName} />
                </div>

                {/* name, active/inactive status text */}
                <div className='d-flex column information-container-cp'>
                  <div className='d-flex items-center'>
                    <p className='dark-mode-text'>{nickName || fullName}</p>
                    <PiCaretRightBold color='gray' size={12}/>
                  </div>
                    {/* active status [online/offline] */}
                    <p className='status-text'>{isActive ? 'online': 'offline'}</p>
                </div>
              </div>
            </div>

            <div className='d-flex items-center position-relative'>
              {/* Book/Calendar button */}
              {!userInfo?.isGuide &&
                <ButtonComponent onClick={()=>{setShowInfoContent(false);setShowDrawer(!showDrawer)}} title='Book' wrapperContainerStyle={{backgroundImage:"linear-gradient(to right, white, white)", padding:'5px 10px', marginRight:10}} style={{color:"var(--primary)", fontSize:14}} />
              }

              {/* Three vertical dots */}
              <PiDotsThreeVerticalBold size={30} onClick={()=>setShowModal(!showModal)}/>
              
              <div className={`chat-settings-modal ${showModal ? 'active' : "hide"}`} onClick={()=>setShowModal(false)}>
                  <div onClick={()=>{setShowDrawer(!showDrawer);setShowInfoContent(true)}} className='cursor-pointer d-flex items-center my-10 px-10'>
                    <PiInfo  className='dark-mode-text'/>
                    <p className='dark-mode-text'>Help</p>
                  </div>
                  <div onClick={()=>{setShowReportModal(true);setShowModal(false)}} className='d-flex items-center my-10 px-10 cursor-pointer'>
                    <PiWarningLight color='red'/>
                    <p style={{color:"red"}}>Report</p>
                  </div>
                  <div onClick={()=>blockHandler()} className='cursor-pointer d-flex items-center my-10 px-10'>
                    <MdBlock  className='dark-mode-text'/>
                    <p className='dark-mode-text'>{isBlocked && blockedByMessage.toLowerCase() === 'you have blocked this user.' ? 'Unblock' : 'Block'}</p>
                  </div>
                 
              </div>
            </div>
        </div>
        <div className={`${darkMode ? 'hc-dark-mode': ''} d-flex w-100`} style={{marginBottom:82}} onClick={()=>setShowModal(false) }>
          {loading ? 
          <Loader style={{marginTop: '50%', alignSelf: 'center', minHeight: '70vh', width: '100%'}}/>
          :
          <>
              <div className='w-100' style={{marginBottom:10}}>
                  {isBlocked && 
                    <div className='position-absolute border-rounded bg-white my-10 width-max-content' style={{left:'50%', transform:'translateX(-50%)', marginTop:100, marginRight:0}}>
                      <p className='px-10 fs-14'>{blockedByMessage}</p>
                    </div>
                  }
                  {messages.map((item, index) => {
                    let media = []
                    if(item?.content?.includes("/media/chat_media/")){
                      media = item.content.split(",")
                    }
                    const self = item.sender !== userInfo?.username
                    const {formattedTime, formattedDate, customFormattedDate} = convertDateTime(item.timestamp)
                    const shouldShowDateSeparator = index === 0 || (messages[index - 1] && convertDateTime(messages[index - 1].timestamp).formattedDate !== formattedDate);
                    
                    return(
                      <React.Fragment key={index}>
                        {shouldShowDateSeparator && (
                          <LineText textStyle={{background:"lightgray", color:"black", padding:"4px", borderRadius:8}} style={{border:'none'}} text={customFormattedDate}/>
                        )}

                        <ProfileMessage 
                          // image={item.profileImage} 
                          message={item.content}
                          username={userInfo.username}
                          // date={formattedDate}
                          time={formattedTime}
                          self={self}
                          media={media}
                        />
                      </React.Fragment>
                    )
                  })}
              </div>
              <div className='d-flex items-center input-container-cp'>
                <input disabled={isBlocked} onChange={handleImageUpload} multiple accept="image/png, image/gif, image/jpeg" ref={inputRef} type='file' hidden/>
                <input disabled={isBlocked} placeholder={`${isBlocked ? 'You cannot send message to this user' : 'Type your message...'}`} value={newMessage} onChange={(e)=>setNewMessage(e.target.value)}/>
                <div className='media-preview'>
                  {selectedImages.slice(0,5).map((image, index) => (
                    <div key={index} className="image-wrapper">
                      <img src={image?.filename?.includes('http') ? image : URL.createObjectURL(image)} alt={`upload-${index}`} style={{ borderRadius: 5, height: 30, width: 30 }} />
                      <button onClick={() => removeImage(index)} className="remove-btn">&times;</button>
                    </div>
                  ))}
                </div>
                <PiImage onClick={()=>inputRef?.current.click()} style={{position:"absolute", background:"var(--primary)", color:"white", height:"26px", marginLeft:-4, width:"26px", borderRadius:'50%', padding:"5px"}} color='var(--primary)' />
                <div onClick={()=>sendMessage()}  className='d-flex column items-center space-evenly' style={{background:"white", height:'30px', width:"60px", padding:"4px", borderRadius:"56px", marginRight:5}}>
                  <PiPaperPlaneTilt size={26} color='var(--primary)' />
                </div>
              </div>  
          </>
          }
        </div>
      </div>
      <BottomDrawer
          state={showDrawer}
          content={
                  showInfoContent ?
                    <InfoContent onClick={()=>setShowDrawer(!showDrawer)}/>
                  :
                    <BasicBookingForm
                      guideUsername={username}
                      image={image}
                      fullName={nickName || fullName}
                      onClick={()=>setShowDrawer(!showDrawer)}
                      user1_id={userInfo?.id}  
                      user1_username={userInfo?.username}
                      guideId={userId}
                    />
                  }
          yValue={showInfoContent ? 50 : 90}
        />
    </div>

  )
}

export default ChatPage