import React, { useContext, useEffect, useState } from 'react'
import { BackButton, BottomDrawer, UserImageCard } from '../../components'
import { PiBank, PiCheck, PiEye, PiEyeSlash, PiPlusBold, PiWallet } from 'react-icons/pi'
import './mywalletpage.styles.scss'
import { FaSlidersH } from 'react-icons/fa';
import { fetchOrderDetails } from '../../utils/service';
import { useNotify } from '../../components/Toast';
import { StateContext } from '../../context/stateContext';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web'
import WithdrawlPage from '../WithdrawlPage';

const MyWallet = () => {
  const [data, setData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [bankVerified, setBankVerified] = useState(null)
  const {userInfo, darkMode} = useContext(StateContext)
  const [totalSpent, setTotalSpent] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [refund, setIsRefund] = useState(true)
  const [showAmount, setShowAmount] = useState(true)
  const [showWalletPage, setShowWalletPage] = useState(false)
  const { notify } = useNotify()
  const navigate = useNavigate()

  useEffect(()=>{
    fetchOrderDetails().then(res => {
      setData(res.data.data)
      setOriginalData(res.data.data)
      // TODO: latest
      let total = 0
      res.data.data.forEach(item => {
        if(!item.isRefund){
          total -= item.amount
        }
        else{
          total += item.amount
        }
      })
      setTotalSpent(Math.abs(total))
      setBankVerified(res.data.verified)
    }).catch(err => {
      notify(err.response && err.response.data.detail ? err.response.data.detail : err.message)
    })
  //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(refund === null){
      setData(originalData)
      return
    }
    if(refund){
      const results = originalData.filter(item => item.isRefund)
      setData(results)
      return
    }
    const results = originalData.filter(item => !item.isRefund)
    setData(results)
    // eslint-disable-next-line
  },[refund])

  const slideAnimation = useSpring({
    transform: !showModal ? 'translateY(20%)' : 'translateY(0%)',
    config: { tension: 100, friction: 10 },
  });
  return (
    <div className={`${darkMode ? 'dark-mode' : ''}`}>
        <BackButton />
        <div className='px-20'>
            {/* Icons */}
            <div className='d-flex space-between px-10 items-center'>
                <p className='px-20 text-title fw-600'>My Wallet</p>
                <div className={`d-flex position-relative ${!userInfo?.isGuide ? 'hide': ''}`}>
                  <PiBank color='gray' size={30}/>
                  {bankVerified === true ?
                    <PiCheck color='gray' style={{position:"absolute", background:'white', right:-4, bottom:-4}} className='bank-svg'/>
                    :
                    bankVerified === false ?
                      <PiPlusBold onClick={()=>navigate('/Profile-Verification/bank-details-verification')} color='gray' style={{position:"absolute", background:'white', right:-4, bottom:-4}} className='bank-svg'/>
                      :
                      ''
                  }
                </div>
            </div>

            {/* Container */}
            <div className='mywallet-balance-container'>
              <div className='circle-1'></div>
              <div className='circle-2'></div>
              {userInfo?.isGuide ?
                <p>My Balance</p>
                :
                <p>Total Spent</p>
              }
              <div className='d-flex items-center'>
                {!showAmount ?
                  <span style={{fontSize:'30px'}}>{totalSpent}</span>
                  :
                  <p style={{fontSize:'30px'}}>🙊🙈🙉</p>
                }
                <div className='d-flex position-absolute' style={{right:10}}>
                  {showAmount ?
                    <PiEyeSlash size={25} color='white' onClick={()=>setShowAmount(!showAmount)}/>
                    :
                    <PiEye size={25} color='white' onClick={()=>setShowAmount(!showAmount)}/>
                  }
                </div>
              </div>

            </div>

            <div style={{marginTop:10}} className='my-5 d-flex flex-end'>
              <div className='p-2 d-flex items-center border-rounded-primary'>
                <PiWallet size={20} color='var(--primary)' />
                <p onClick={()=>setShowWalletPage(prev => !prev)} className='p-2 fs-14 fw-500 text-primary '>Withdraw</p>
              </div>
            </div>

            <div className='d-flex space-between items-center relative'>
              <h1 className='fw-600 fs-18 dark-mode-text'>Recent Transactions</h1>
              <FaSlidersH className='slider-svg' onClick={()=>setShowModal(!showModal)}/>
            </div>
            <div className='d-flex items-center position-relative'>
              <animated.div style={slideAnimation} className={`${showModal ? '' : 'hide'} modal-container sort-modal-container-wallet-page`}>
                <p onClick={()=>{setIsRefund(true);setShowModal(false)}} className={`${refund ? 'active' : ""}`}>Refund</p>
                <p onClick={()=>{setIsRefund(false);setShowModal(false)}} className={`${refund === false? 'active' : ""}`}>Paid</p>
                <p onClick={()=>{setIsRefund(null);setShowModal(false)}} className={`${refund === null ? '' : ""}`}>Cancel</p>
              </animated.div>
            </div>
            <br />
            {data.length === 0 && <p className='text-center dark-mode-text'>No recent transactions.</p>}
            {data?.map(item => {
              let sign = item.isRefund ? '+' : '-'
              sign = userInfo?.isGuide ? '+' : sign
              return(
                <UserImageCard 
                  key={item.id}
                  image={userInfo?.isGuide ? item.image : item.guideProfileImage}
                  priceText={sign+String(item.amount)}
                  fullName={userInfo?.isGuide ? item.fullName : item.guideName}
                  created={item.createdAt}
                  transactionId={item.id}
                  id='a'
                  activeModalId='x' // to hide the modal
                  showModal={false}
                />
              )
            })}
        </div>
        <BottomDrawer 
          content={<WithdrawlPage hideModal={()=>setShowWalletPage(false)} />}
          state={showWalletPage}
          yValue={100}

        />
    </div>
  )
}

export default MyWallet