import React from 'react'
import './d-eventscard.styles.scss'
// import { PiHeart, PiHeartFill } from 'react-icons/pi'

const DEventsCard = ({image, month, date, title, desc, isSaved}) => {
  // const [saved, setSaved] = useState(isSaved)
  return (
    <div className='desktop-events-card'>
        <div className='heart-container'>
          {/* {saved ?
            <PiHeartFill onClick={()=>setSaved(!saved)} color='orange'/>
            :
            <PiHeart onClick={()=>setSaved(!saved)} color='orange'/>  
          } */}
        </div>
        <img src={image} alt={title} />
        <div className='d-flex my-10'>
            <div className='w-25 d-flex column items-center'>
                <p className='text-primary fw-500 fs-12'>{month}</p>
                <p className='fw-600 fs-18'>{date}</p>
            </div>
            <div className='w-75 desc-container'>
                <p className='fw-500 fs-14'>{title}</p>
                <p className='fs-14 text-lightgray'>{desc?.length > 130 ? desc?.slice(0, 130) + '...' : desc}</p>
            </div>
        </div>
    </div>
  )
}

export default DEventsCard