import React, { useRef, useState } from 'react'
import './iconinput.styles.scss'

const IconInput = ({Icon, EndIcon, endIconSize, endIconColor, iconSize, hideLabel, endIconFunction=()=>true, ...props}) => {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef()
  return (
    <div className='InputBox'>
        {/* icon */}
        {Icon &&
          <Icon size={iconSize || 20} style={{paddingLeft:10}} color='gray' />
          }
        <label onClick={()=>{setIsFocused(true); inputRef?.current?.focus()}} className={`label ${isFocused || props?.value?.length > 0  ? 'active' : ""}`}>{hideLabel && !isFocused ? '' : props.placeholder}</label>
        <input ref={inputRef} onClick={()=>inputRef.current.focus()} onFocus={()=>setIsFocused(true)} onBlur={()=>setIsFocused(false)} {...props} placeholder='' />
        {EndIcon &&
          <EndIcon onClick={endIconFunction} size={endIconSize || 20} style={{marginRight:'10px'}} color={endIconColor || 'gray'} />
        }
    </div>
  )
}

export default IconInput