import React, { useContext, useState } from 'react'
import { IconInput } from '../../components'
import { wizard3 } from '../../assets/images'
import { PiPassword, PiUser, PiWhatsappLogo } from 'react-icons/pi'
import Wrapper from './Wrapper'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { StateContext } from '../../context/stateContext'
import { PersonalizeAccountDesktop } from '../../assets/desktop-svgs'

const PersonalizeAccount = () => {
    const [showPassword, setShowPassword] = useState(false)
    const {userForm, setUserForm} = useContext(StateContext)
    const [isChecked, setIsChecked] = useState(userForm?.sameAsContactNumber || false)
    console.log('userForm', userForm)
    const checkHandler = () => {
        setUserForm({...userForm, sameAsContactNumber:!isChecked, whatsappNumber:!isChecked ? userForm?.contact : ''})
        setIsChecked(!isChecked)
    }

    const changeHandler = (key, text) => {
        if(key === 'username'){
            if(text.includes(' ')){
                return
            }
        }
        setUserForm({...userForm, [key]:text})
    }

    return (
      <Wrapper image={wizard3} desktopImage={PersonalizeAccountDesktop} slideIndex={3} title='Personalize Account' nextButtonText="Let's Explore" nextButtonUrl='/Home' submit={true}>
          {/* Input Fields [ username, password, whatsapp number ] */}
          <IconInput style={{textTransform:"lowercase"}} value={userForm?.username} onChange={(e)=>changeHandler('username', e.target.value?.toLowerCase())} Icon={PiUser } iconSize={30} placeholder='Username' />
          <IconInput value={userForm?.password} onChange={(e)=>setUserForm({...userForm, 'password':e.target.value} )} Icon={PiPassword } type={`${showPassword ? 'text' : 'password'}`} iconSize={30} placeholder='Set Password' EndIcon={ showPassword ? BsEye : BsEyeSlash } endIconFunction={()=>setShowPassword(!showPassword)} />
          <div className='px-4'>
            <p className='fw-400 fs-12 dark-mode-text'>• At least 8 characters long</p>
            <p className='fw-400 fs-12 dark-mode-text'>• Contains at least one uppercase letter, lowercase letter, one digit & special characher.</p>
          </div>
          <IconInput disabled={isChecked} value={userForm?.whatsappNumber} onChange={(e)=>setUserForm({...userForm, 'whatsappNumber':e.target.value})} Icon={PiWhatsappLogo } iconSize={30} placeholder='Whatsapp Number' />

          {/* checkbox [ age ]  */}
          <div className='d-flex items-center flex-end' style={{margin:"-15px -10px 0px 0px"}}>
              <input checked={userForm?.sameAsContactNumber} onChange={()=>checkHandler()} type='checkbox' style={{margin:'10px 10px 10px 0px', height:17, width:17}} />
              <p style={{fontSize:13, color:'var(--gray)'}} className='dark-mode-text'>Same as contact number.</p>
          </div>
          
          {/* checkbox [ age ]  */}
          <div className='d-flex items-center'>
              <input checked={userForm?.ageChecked} onChange={()=>changeHandler('ageChecked', !userForm?.ageChecked)} type='checkbox' style={{margin:'10px 10px 10px 0px', height:17, width:17}} />
              <p style={{fontSize:13, color:'var(--gray)'}} className='dark-mode-text'>Yes, I'm 18 years of age or older.</p>
          </div>
        
          {/* checkbox [ T&C ]  */}
          <div className='d-flex items-center'>
              <input checked={userForm?.termsChecked} onChange={()=>changeHandler('termsChecked', !userForm?.termsChecked)} type='checkbox' style={{margin:'10px 10px 10px 0px', height:17, width:17}} />
              <p style={{fontSize:13, color:'var(--gray)'}} className='dark-mode-text'>I agree to the Terms of Service and Privacy Policy.</p>
          </div>
          <br/>
      </Wrapper>
    )
}

export default PersonalizeAccount