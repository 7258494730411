import React, { useContext, useEffect, useState } from 'react'
import './sidedrawer.styles.scss'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { PiBellFill, PiBookmarkThin, PiCirclesThreePlusThin, PiCrownThin, PiHeadsetThin, PiLockSimpleThin, PiNote, PiNotePencil, PiPencil, PiPhoneCall, PiSealCheckThin, PiWalletThin } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { CiSettings } from 'react-icons/ci'
import CircularProgress from '../CircularProgress'
import { StateContext } from '../../context/stateContext'
import { MdOutlineSos, MdVerified } from 'react-icons/md'
import { guideProgressBar, updateNotificationMetaInformation, userActive } from '../../utils/service'

const DRAWER_ITEMS = [
	{Icon:PiWalletThin, name:'My Wallet', url:'/My-Wallet'},
	{Icon:PiCrownThin, name:'Membership', url:'/Membership'},
	{Icon:PiSealCheckThin, name:'Profile Verification', url:'/Profile-Verification/tourist-identity-verification'},
	{Icon:PiBookmarkThin, name:'Favourite Places & Profile', url:'/Favourites'},
	{Icon:CiSettings, name:'Settings', url:'/Settings'},
	// {Icon:PiTranslateThin, name:'Language', url:''},
	{Icon:PiCirclesThreePlusThin , name:'More', url:'', 
        options:[
                // {Icon:PiNote, name:'terms and conditions', url:'/Terms-Conditions'},

                {Icon:PiNote, name:'FAQ', url:'/faq'},
                {Icon:PiNotePencil, name:'Feedback', url:'/Feedback'},
                {Icon:PiPhoneCall, name:'Contact Us', url:'/ContactUs'}]},
	{Icon:PiHeadsetThin , name:'Support', url:'/Support'},
	{Icon:PiHeadsetThin , name:'SOS', url:'/Emergency'},
];

const DRAWER_ITEMS_GUIDE = [
	{Icon:PiWalletThin, name:'My Wallet', url:'/My-Wallet'},
	{Icon:PiCrownThin, name:'Membership', url:'/Membership'},
	{Icon:PiSealCheckThin, name:'Profile Verification', url:'/Profile-Verification/identity-verification'},
	{Icon:CiSettings, name:'Settings', url:'/Settings'},
	// {Icon:PiImageThin, name:'Itinerary', url:'/Itinerary'},
	{Icon:PiCirclesThreePlusThin , name:'More', url:'', 
        options:[
				{Icon:PiNote, name:'FAQ', url:'/faq'},
                {Icon:PiNotePencil, name:'Feedback', url:'/Feedback'},
                {Icon:PiPhoneCall, name:'Contact Us', url:'/ContactUs'}]},
	{Icon:PiHeadsetThin , name:'Support', url:'/Support'},
	{Icon:PiHeadsetThin , name:'SOS', url:'/Emergency'},
];

const ListItem = ({Icon, name, url, state, showBadge, setState, profileVerified, options=[]}) => {
	const navigate = useNavigate()
	return(
		<>	
			{name === 'SOS' &&
				(
					<div className='list-item emergency-number' onClick={()=>navigate('/Emergency')}>
						{/* <h4 className='item-text'>SOS</h4> */}
						<MdOutlineSos color='#969696' size={45}/>
					</div>	
				)
			}
			{name !== 'SOS' &&
				<>
					<div key={`${name}-${url}`} className={`list-item ${state ? 'mb-0' : ''}`} onClick={options.length > 0 ? ()=>setState(!state) : url?.length === 0 ? ()=>true : ()=>navigate(url)}>
						<Icon className='dark-mode-text' size={24}/>
						<h4 className='px-5 item-text'>{name}</h4>
						{profileVerified && name.includes('Verification') &&
							<span style={{marginLeft:20}} className='text-primary fs-14 px-5 border-rounded-primary'>verified</span>
						}
						{showBadge &&
							<div style={{height:15, width:15, marginLeft:5, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"50%", backgroundColor:'var(--primary)'}}>
								<p style={{fontSize:10, marginTop:2, color:"white"}}>5</p>
							</div>
						}
					</div>
					{state &&
						<div className={`options-container`}>
							{options.map((item, index) => (
								<div className=' d-flex items-center' onClick={()=>navigate(item.url)} key={index}>
									<item.Icon />
									<p className='cursor-pointer item-text'>{item.name}</p>
								</div>
							))}
						</div>
					}
				</>
			}
		</>
	)
}

const SideDrawer = ({isOpen, setIsOpen}) => {
	const navigate = useNavigate()
	const { darkMode, userInfo, userForm, setUserInfo, notificationIds, showNotificationBadge, setShowNotificationBadge} = useContext(StateContext)
	const [showOptions, setShowOptions] = useState(false)
	const [percentage, setPercentage] = useState(0)

	useEffect(()=>{
		let about  = userForm?.about ? 10 : 0
		let desc = userForm?.description ? 10 : 0
		let profileImage = userForm?.profileImage ? 12 : 0
		let image1 = userForm?.image1 ? 2 : 0
		let image2 = userForm?.image2 ? 2 : 0
		let image3 = userForm?.image3 ? 2 : 0
		let image4 = userForm?.image4 ? 2 : 0
		if(userInfo?.isGuide){
			guideProgressBar().then(res =>{
				setPercentage(res.data.percentage)
				}).catch(err => {
				console.log(err)
				})
		}
		else{
			setPercentage(60 + about+ desc+ profileImage+ image1+ image2+ image3+ image4)
		}
		// eslint-disable-next-line
	},[userForm])

	const logoutHandler = () => {
		userActive(false).then(() => {
			setUserInfo(false)
			localStorage.removeItem('auth-cg')
			window.location.reload()
		}).catch(() => {})
	}

	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState)
	}

	const badgeStatusToggle = () => {
		setShowNotificationBadge(false)
		const record = notificationIds.find(item => item.eventType === "notification")
		if(record){
			updateNotificationMetaInformation(record.id).catch(err=>{})
		}
		navigate('/NotificationPage')
	}

	return (
		<Drawer
			open={isOpen}
			style={{
				borderLeft: '0.2px solid #d3d3d363',
				boxShadow: 'rgba(211, 211, 211, 0.54) -8px -10px 2px -7px',
				right: '-1px',
			}}
			onClose={toggleDrawer}
			direction='right'
			className={`px-10 ${darkMode ? 'dark-mode' :''}`}
			size='77%'
		>
			{/* Content */}
			<div className='profile-info-container my-20 d-flex space-between'>
				<div onClick={userInfo?.isGuide ? ()=>navigate('/Guide-Details', {state:{id:userInfo?.userDetailsId, userId:userInfo?.userDetailsId, isProfileView:true}}) : ()=>navigate('/My-Profile')} className='left-container'>
					{/* Image */}
					<CircularProgress percentage={percentage} image={userInfo?.profileImage} fullName={userInfo?.fullName}/>
					{/* Name */}
					<div className='d-flex items-center'>
						<h1 className='title-text dark-mode-text'>{userInfo?.fullName}</h1>
						{userInfo?.is_active &&
							<MdVerified style={{marginTop:12, marginLeft:5, color:"orange !important", fill:"orange"}} size={18} />
						}
					</div>
					{/* Email */}
					<p className='email-text'>{userInfo?.email}</p>
				</div>
				<div className='d-flex column  space-between'>
					{/* Bell icon */}
					<div className='bell-container' onClick={()=>{badgeStatusToggle()}}>
						<PiBellFill className='dark-mode-gray-text'/>
						{showNotificationBadge &&
							<div className='badge d-flex column items-center' style={{border:"none", height:12, width:12}}>
								<span style={{color:"white", marginTop:'-3.5px'}}>*</span>
							</div>
						}
					</div>
					<div onClick={()=>navigate("/Edit-Profile")} className='edit-container-sd'>
						<PiPencil className='dark-mode-gray-text'/>
					</div>
				</div>
			</div>
			{/* Drawer Items */}
			<div className='position-relative'>
				{(userInfo?.isGuide ? DRAWER_ITEMS_GUIDE : DRAWER_ITEMS).map(({Icon, name, url, options}, index)=>{
					return(
						<ListItem profileVerified={userInfo?.verified} showBadge={name === 'support'} key={index} Icon={Icon} name={name} url={url} state={name=== 'More' && showOptions} setState={name === 'More' && setShowOptions} options={options}/>
					)
				})}
				<div onClick={()=>logoutHandler()}>
					<ListItem Icon={PiLockSimpleThin} name={'Logout'} />
				</div>
			</div>
		</Drawer>
	)

}

export default SideDrawer
